import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    onlineUsers: [],
    currentUser: {},
    userProfile: {},
    calleeDecList: [],
    UsersInSession:[],
    IsWaitingForMembers:true,
    isForceLogin :false
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        addUser(state, action) {   
            return { ...state, ...action.payload };
        },
        setOnlineUsers(state, action) {            
            state.onlineUsers = action.payload.online_users;
        },
        setUserProfile(state, action) {
            state.userProfile = action.payload.user_profile;
        },
        setCalleeList(state, action) {
            state.calleeList = action.payload.calleeList;
        },
        setIsCurrentUserOnCall(state, action) {
            state.currentUser.isOnCall = action.payload.isOnCall
        },
        setCalleeDecList(state, action) {
            state.calleeDecList = action.payload.calleeDecList
        },
        setIsWaitingForMembers(state, action) {        
            state.IsWaitingForMembers = action.payload;
        },
        setCurrentUserisForceLogin(state, action) {        
            state.isForceLogin = action.payload;
        }
        
    }
});

export default userSlice.reducer;
export const { 
    addUser,
    setOnlineUsers,
    setUserProfile,
    setCalleeList,
    setIsCurrentUserOnCall,
    setCalleeDecList,
    setIsWaitingForMembers,
    setCurrentUserisForceLogin
} = userSlice.actions;
