import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTokenFromSession } from "../../../helpers.js";
import { workflow_api } from "../../../API/services/workflow.js";

const initialState = {
  assigneesData: [],
  assetData: [],
  loading: false,
  error: null,
  assignJobStatus: null,
  taskDetails:[],
  taskExecutionStatus: null,
};

// fetchAssignees Thunk
export const fetchAssignees = createAsyncThunk(
  "assignees/fetchAssignees",
  async (assigneeType, { rejectWithValue }) => {
    try {
      const token = await getTokenFromSession();

      const response = await axios.get(`${workflow_api.getAssigneesList.url}?assignees=${assigneeType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// assignJob Thunk
export const assignJob = createAsyncThunk("assignees/assignJob", async ({ jobId }, { rejectWithValue }) => {
  try {
    const token = await getTokenFromSession();
    const response = await axios.post(
      `${workflow_api.assignJob.url}`,
      { job_id: jobId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// fetchTaskDetails Thunk
export const fetchTaskDetails = createAsyncThunk("tasks/fetchTaskDetails", async (jobId, { rejectWithValue }) => {
    try {
      const token = await getTokenFromSession();
      const response = await axios.get(
        `${workflow_api.getTaskDetails.url}/${jobId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// executeManualEntryTask Thunk
export const executeManualEntryTask = createAsyncThunk(
  "tasks/executeManualEntryTask",
  async (taskPayload, { rejectWithValue }) => {
    try {
      const token = await getTokenFromSession();
      const response = await axios.put(
        `${workflow_api.taskExecution.url}`,
        taskPayload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const assigneeSlice = createSlice({
  name: "assigneeInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssignees.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAssignees.fulfilled, (state, action) => {
        state.loading = false;
        if (action.meta.arg == "technician") {
          state.assigneesData = action.payload;
        } else {
          //assets
          state.assetData = action.payload;
        }
      })
      .addCase(fetchAssignees.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle assignJob cases
      .addCase(assignJob.pending, (state) => {
        state.assignJobStatus = "loading";
        state.error = null;
      })
      .addCase(assignJob.fulfilled, (state) => {
        state.assignJobStatus = "success";
      })
      .addCase(assignJob.rejected, (state, action) => {
        state.assignJobStatus = "failed";
        state.error = action.payload;
      })
      .addCase(fetchTaskDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTaskDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.taskDetails = action.payload;
      })
      .addCase(fetchTaskDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(executeManualEntryTask.pending, (state) => {
        state.taskExecutionStatus = "loading";
        state.error = null;
      })
      .addCase(executeManualEntryTask.fulfilled, (state) => {
        state.taskExecutionStatus = "success";
      })
      .addCase(executeManualEntryTask.rejected, (state, action) => {
        state.taskExecutionStatus = "failed";
        state.error = action.payload;
      });
  },
});

export default assigneeSlice.reducer;
