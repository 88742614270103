import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { asset } from "../../../API/services/asset.js";
import { getTokenFromSession } from "../../../helpers";
import axios from "axios";

const initialState = {
  assetFormData: [],
  selectedAssetId: undefined,
  isLoading: false,
  errorMessage: undefined,
};

//create asset
export const createAssetAPI = createAsyncThunk("create/asset", async (payload, { rejectWithValue }) => {
  try {
    const { createdAt, created_by, is_deleted, updatedAt,location, updated_by, ...updatedPayload } = payload;
    const response = await axios.post(`${asset.create.url}`, updatedPayload, {
      headers: asset.create.headers,
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// get all assets
export const getAllAssetsAPI = createAsyncThunk("get/all-assets", async (_, { rejectWithValue }) => {
  try {
    const token = await getTokenFromSession();
    const response = await axios.get(`${asset.getAllAssets.url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// update asset
export const updateAssetAPI = createAsyncThunk("update/asset", async (payload, { rejectWithValue }) => {
  try {
    const { createdAt, created_by, is_deleted, updatedAt,location, updated_by, ...updatedPayload } = payload;
    const response = await axios.put(`${asset.edit.url}`, updatedPayload, {
      headers: asset.edit.headers,
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

//delete asset
export const deleteAssetAPI = createAsyncThunk("delete/asset", async (assetId, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`${asset.delete.url}?asset_id=${assetId}`, {
      headers: asset.delete.headers,
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const assetSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    updateSelectedAsset(state, action) {
      state.assetFormData = action.payload.selectedAssetData;
    },
  },
  extraReducers: (builder) => {
    // create asset API
    builder.addCase(createAssetAPI.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(createAssetAPI.fulfilled, (state, action) => {
        state.assetDetails = action.payload;
        state.isLoading = false;
      }),
      builder.addCase(createAssetAPI.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.isLoading = false;
      }),
      // get All Asset API
      builder.addCase(getAllAssetsAPI.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(getAllAssetsAPI.fulfilled, (state, action) => {
        state.getAllAssets = action.payload.data;
        state.isLoading = false;
      }),
      builder.addCase(getAllAssetsAPI.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.isLoading = false;
      });
    // update Asset API
    builder.addCase(updateAssetAPI.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(updateAssetAPI.fulfilled, (state, action) => {
        state.updatedAssetRes = action.payload.data;
        state.isLoading = false;
      }),
      builder.addCase(updateAssetAPI.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.isLoading = false;
      });
  },
});

export default assetSlice.reducer;
export const { updateSelectedAsset } = assetSlice.actions;
