import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    forgotPassword:false,
};

const forgotPasswordSlice = createSlice({
    name: "forgotPassword",
    initialState,
    reducers: {
        resetForgotPassword(state, action) {
            // console.log(action.payload);
            return { ...state, forgotPassword:action.payload };
        },
    }
});

export default forgotPasswordSlice.reducer;
export const { resetForgotPassword } = forgotPasswordSlice.actions;