import { StreamVideoClient } from "@stream-io/video-react-sdk";

//for initializing video client
export const createStreamClient = (user: any, token: string, API_KEY: string) => {
  const client = new StreamVideoClient({ apiKey: API_KEY });
  user?.id !== undefined &&
    client.connectUser(
      {
        id: user.id,
        name: user.name, 
      },
      token
    );
  return client;
};

