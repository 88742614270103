import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Thunk to fetch knowledge base data
export const fetchKnowledgeBase = createAsyncThunk(
  'knowledgeBase/fetchKnowledgeBase',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('https://kb.telepresenz.com/api/knowledge_base');
      if (!response.ok) {
        throw new Error('Failed to fetch knowledge base data');
      }

      
      return await response.json(); // Parse response JSON
    } catch (error) {
      return rejectWithValue(error.message); // Pass error to rejectWithValue
    }
  }
);

const knowledgeBaseSlice = createSlice({
  name: 'knowledgeBase',
  initialState: {
    knowledgeBaseData: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchKnowledgeBase.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchKnowledgeBase.fulfilled, (state, action) => {
        state.loading = false;
        state.knowledgeBaseData = action.payload;
      })
      .addCase(fetchKnowledgeBase.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default knowledgeBaseSlice.reducer;
