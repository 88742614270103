import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment, useState, useEffect } from "react";
import Loader from "react-loaders";

import { toast, ToastContainer } from "react-toastify";
import ForgotPassword from "../../DemoPages/UserPages/ForgotPasswordComponent/ForgotPassword";
import { useDispatch, useSelector } from "react-redux";
// import ChatRouter from "../../DemoPages/Chat/";
import { fetchDecryptedUser } from "../../helpers";
import SendbirdChat from '@sendbird/chat';
import {  GroupChannelModule,GroupChannelHandler } from "@sendbird/chat/groupChannel";
import { setTotalUnreadMessages } from "../../store/features/chats/chatslice";
// import ChatRouter from "../../DemoPages/Chat/";
// import Chat from "../../DemoPages/Chat/index.js";

const LoginPage = lazy(() => import("../../DemoPages/UserPages/Login/Login"));
const RegisterPage = lazy(() => import("../../DemoPages/UserPages/Register/Register"));
const Home = lazy(() => import("../../DemoPages/Home"));
const Account = lazy(() => import("../../DemoPages/Account"));
const Customer = lazy(() => import("../../DemoPages/Customer"));
const WorkFlow = lazy(() => import("../../DemoPages/WorkFlow"));
const Session = lazy(() => import("../../DemoPages/Session"));
const SmartITMPages = lazy(() => import("../../DemoPages/SmartITM"));
const UserProfile = lazy(()=> import("../../DemoPages/UserProfile"));
const SessionWrapper = lazy(()=>import("../../DemoPages/Session/getstream_io"));
const ChatRouter = lazy(() => import("../../DemoPages/Chat/"));

const AppMain = () => {
    return (
        <Fragment>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="line-scale" />
                        </div>
                        <h6 className="mt-3">
                            Loading Content...
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/workflow" component={WorkFlow} />
            </Suspense>

            {/* Customer */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="line-scale" />
                        </div>
                        <h6 className="mt-3">
                            Loading Content...  
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/customer" component={Customer} />
            </Suspense>

            {/* Account */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="line-scale" />
                        </div>
                        <h6 className="mt-3">
                            Loading Content...
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/account" component={Account} />
            </Suspense>

            {/* Session */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="line-scale" />
                        </div>
                        <h6 className="mt-3">
                            Loading Content...
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/session" component={Session} />
            </Suspense>

            {/* getStream.io */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="line-scale" />
                        </div>
                        <h6 className="mt-3">
                            Loading Content...
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/session-stream" component={SessionWrapper} />
            </Suspense>
           

            {/* Home */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="line-scale" />
                        </div>
                        <h6 className="mt-3">
                            Loading Content...
                            {/* <small>{`We load at once all the Dashboards pages.`}</small> */}
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/home" component={Home} />
                <Route exact path="/home/user-dashboard" render={() => (
                    <Redirect to="/home/user-dashboard" />
                )} />
                <Route exact path="/" component={LoginPage} />
            </Suspense>

            {/* Register */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="line-scale" />
                        </div>
                        <h6 className="mt-3">
                            Loading Content...
                        </h6>
                    </div>
                </div>
            }>
                <Route exact path="/register/:user_id" component={RegisterPage} />
            </Suspense>

              {/* Forgot Password */}
              <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="line-scale" />
                        </div>
                        <h6 className="mt-3">
                            Loading Content...
                        </h6>
                    </div>
                </div>
            }>
                <Route exact path="/forgot-password/:user_id" component={ForgotPassword} />
            </Suspense>


              {/* Smart ITM */}
              <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="line-scale" />
                        </div>
                        <h6 className="mt-3">
                            Loading Content...
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/smart-itm" component={SmartITMPages} />
            </Suspense>

            {/** User Profile/Change Password pages */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="line-scale" />
                        </div>
                        <h6 className="mt-3">
                             Loading Content...
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/user" component={UserProfile} />
            </Suspense>

            {/* stream chat page */}
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="line-scale" />
                        </div>
                        <h6 className="mt-3">
                            Loading Content...
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/chat" component={ChatRouter} />

            </Suspense>


            <ToastContainer />
        </Fragment>
    )
};

export default AppMain;
