import { getTokenFromSession } from "../../helpers";

let backend_domain = window._env_.REACT_APP_API_URL;
let api_version = window._env_.REACT_APP_API_VERSION;

const token = await getTokenFromSession();

//work flow api's
export const workflow_api = {
  // common api's
  getTaskCategories: {
    method: "GET",
    url: `${backend_domain}${api_version}/wf/get-task-category`,
    headers: { "Content-Type": "application/json" },
  },
  getTaskEvidences: {
    method: "GET",
    url: `${backend_domain}${api_version}/wf/get-task-evidence`,
    headers: { "Content-Type": "application/json" },
  },
  fileupload: {
    method: "POST",
    url: `${backend_domain}${api_version}/file/upload`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  },

  //api is used to create template and jobs
  createWorkflow: {
    method: "POST",
    url: `${backend_domain}${api_version}/wf/add-job`,
    headers: { "Content-Type": "'multipart/form-data'" },
  },
  getAllWorkflow: {
    method: "POST",
    url: `${backend_domain}${api_version}/wf/get-all-job`,
    headers: { "Content-Type": "application/json" },
  },
  getAllWorkflowDetail: {
    method: "POST",
    url: `${backend_domain}${api_version}/wf/get-job-details`,
    headers: { "Content-Type": "application/json" },
  },
  getAllWorkflowSummary: {
    method: "POST",
    url: `${backend_domain}${api_version}/wf/get-job-summary`,
    headers: { "Content-Type": "application/json" },
  },

  editTemplate: {
    method: "PUT",
    url: `${backend_domain}${api_version}/wf/edit-job`,
    headers: { "Content-Type": "application/json" },
  },
  deleteWorkflow: {
    method: "DELETE",
    url: `${backend_domain}${api_version}/wf/delete-job`,
    headers: { "Content-Type": "application/json" },
  },
  gettaskslist: {
    method: "GET",
    url: `${backend_domain}${api_version}/wf/get-tasks-list`,
    headers: { "Content-Type": "application/json" },
  },
  getAssigneesList: {
    method: "GET",
    url: `${backend_domain}${api_version}/wf/get-assignees-list`,
    headers: { "Content-Type": "application/json" },
  },
  assignJob: {
    method: "POST",
    url: `${backend_domain}${api_version}/wf/assign-job`,
    headers: { "Content-Type": "application/json" },
  },
  getTaskDetails: {
    method: "GET",
    url: `${backend_domain}${api_version}/wf/get-task-details`,
    headers: { "Content-Type": "application/json" },
  },
  taskExecution: {
    method: "PUT",
    url: `${backend_domain}${api_version}/wf/task-execution`,
    headers: { "Content-Type": "application/json" },
  },
};
