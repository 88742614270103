import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StreamVideoClient } from "@stream-io/video-react-sdk";
import { fetchDecryptedUser, printConsole } from "../helpers";

const API_KEY = "9b2z38gjb5ts";

export const StreamClientContext = createContext(null);

export const StreamClientProvider = ({ children }) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const clientRef = useRef(null);
  const [client, setClient] = useState(null);
  const dispatch = useDispatch();

  // const getApiKey = async () => {
  //   try {
  //     const keys = await fetchDecryptedUser(dispatch);
  //     const apiSecret = keys.find((item) => item.service_name === "getstream" && item.key_name === "API_KEY");
  //     return apiSecret.key_value;
  //   } catch (error) {
  //     console.error("Error fetching API keys:", error);
  //   }
  // };

  // let apiKey;

  // getApiKey().then((key) => {
  //   console.log("stream_client_provider_inside", key);
  //   apiKey = key;
  // });
  // console.log("stream_client_provider", apiKey);

  useEffect(() => {
    if (!currentUser?.user_id || !currentUser?.app_tokens?.session_token) {
      console.warn("❌ Stream client cannot be initialized. No user or missing token.");
      return;
    }

    if (!API_KEY) {
      console.error("❌ Missing API key for Stream client.");
      return;
    }

    // Prevent multiple initializations
    if (!clientRef.current) {

      const streamClient = new StreamVideoClient({ apiKey: API_KEY });

      streamClient
        .connectUser({ id: currentUser.user_id, name: currentUser.first_name }, currentUser.app_tokens.session_token)
        .then(() => {
         
          clientRef.current = streamClient;
          setClient(streamClient);
        })
        .catch((error) => {
          console.error("❌ Error connecting Stream client:", error);
        });
    } else {
      
      setClient(clientRef.current);
    }

    return () => {
    printConsole("🔄 Cleanup: Stream client remains active.");
    };
  }, [currentUser]);

  return <StreamClientContext.Provider value={client}>{children}</StreamClientContext.Provider>;
};

export const useStreamClient = () => {
  const client = useContext(StreamClientContext);

  if (!client) {
    console.warn("⚠️ useStreamClient called before StreamClientProvider was ready.");

    return null;
  }

  return client;
};
