import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { sessions_api } from '../../../API/services/session';
import { getTokenFromSession } from '../../../helpers';

const initialState = {
  chats: [],
  isLoading: false,
  isUpdating: false,
  keys: null,
  error: null,
  latestMessage: null,
  totalUnreadMessages: 0,
  UnreadMessageCntInCall:0,
  apiKeys:[]
};

// Async thunk to fetch keys
export const fetchKeys = createAsyncThunk(
  'chat/fetchKeys',
  async (_, { rejectWithValue }) => {
    try {
      const token = await getTokenFromSession();
      const response = await axios.post(
        sessions_api.get_keys.url,
        {},
        {
          headers: {
            ...sessions_api.get_keys.headers,
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    AddToChat: (state, action) => {
      state.chats.push(action.payload);
    },
    UpdateChat: (state, action) => {
      const index = state.chats.findIndex(obj => obj.MsgId === action.payload.MsgId);
      if (index !== -1) {
        state.chats[index] = action.payload;
      }
    },
    setLatestMessage: (state, action) => {
      state.latestMessage = action.payload;
      state.UnreadMessageCntInCall = state.UnreadMessageCntInCall+1;
    },
    setUnreadMessageCntInCall: (state, action) => {
      state.UnreadMessageCntInCall = action.payload;
    },
    clearLatestMessage: (state) => {
      state.latestMessage = null; // Clear the badge when chat opens
      state.UnreadMessageCntInCall = 0;//makes the count to 0
    },
    setTotalUnreadMessages: (state, action) => {
      state.totalUnreadMessages = action.payload;
    },
    clearTotalUnreadMessages: (state) => {
      state.totalUnreadMessages = null;
    },
    clearUnreadMessagesForChannel: (state, action) => {
      const channelUrl = action.payload;
      if (state.totalUnreadMessages[channelUrl] !== undefined) {
        state.totalUnreadMessages[channelUrl] = 0;
      }
    },
    setApiKeys: (state, action) => {
      state.apiKeys = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchKeys.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchKeys.fulfilled, (state, action) => {
        state.isLoading = false;
        state.keys = action.payload;
      })
      .addCase(fetchKeys.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

// Export actions
export const { AddToChat, 
              UpdateChat, 
              setLatestMessage, 
              clearLatestMessage, 
              setTotalUnreadMessages, 
              clearTotalUnreadMessages, 
              setApiKeys, 
              clearUnreadMessagesForChannel ,
              setUnreadMessageCntInCall
            } = chatSlice.actions;

// Export the reducer
export default chatSlice.reducer;
