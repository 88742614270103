import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getTokenFromSession } from "../../../helpers";
import { admin_api } from "../../../API/services/admin";

export const updateProfilePicture = createAsyncThunk(
  'profilePicture/updateProfilePicture',
  async ({ profile_picture }, { rejectWithValue }) => {
    try {
      // console.log("Profile slice", profile_picture);
      const token = await getTokenFromSession();
      const response = await axios.patch(admin_api.updateProfilePic.url, 
        { profile_picture },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // console.log(response.data);
      return response.data;
    
    } catch (error) {

      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const updateProfilePictureSlice = createSlice({
  name: "updateProfilePicture",
  initialState: {
    loading: false,
    success: false,
    error: null,
    updateProfilePicture: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateProfilePicture.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false; 
      })
      .addCase(updateProfilePicture.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.updateProfilePicture = action.payload; 
      })
      .addCase(updateProfilePicture.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      });
  }
});

export default updateProfilePictureSlice.reducer;
