import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import AppMain from "../../Layout/AppMain";
import { toast } from "react-toastify";
import SendbirdChat from '@sendbird/chat';
import {  GroupChannelModule,GroupChannelHandler } from "@sendbird/chat/groupChannel";
import { setTotalUnreadMessages } from "../../store/features/chats/chatslice";
import { fetchDecryptedUser, printConsole } from "../../helpers.js";

const Main = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const dispatch = useDispatch();
      const apiKeys = useSelector((state)=> state.chats.apiKeys);
      const { currentUser } = useSelector((state) => state.user);
      const [apiKey, setApiKey] = useState(apiKeys[0]?.key_value)
      const accessToken = currentUser?.app_tokens?.chat_token;
      const fullname = `${currentUser.first_name} ${currentUser.last_name}`;

  // useSelector
  const colorScheme = useSelector((state) => state.ThemeOptions.colorScheme);
  const enableFixedHeader = useSelector((state) => state.ThemeOptions.enableFixedHeader);
  const enableFixedSidebar = useSelector((state) => state.ThemeOptions.enableFixedSidebar);
  const enableFixedFooter = useSelector((state) => state.ThemeOptions.enableFixedFooter);
  const enableMobileMenu = useSelector((state) => state.ThemeOptions.enableMobileMenu);
  const enablePageTabsAlt = useSelector((state) => state.ThemeOptions.enablePageTabsAlt);
  const userSettings = useSelector((state) => state.userSettings.userSettings);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

 
    useEffect(() => {
          const getApiKey = async () => {
              const key = await fetchDecryptedUser(dispatch);
              if (key?.length > 0) {
                  const apiSecret = key.find((item) => item.service_name === 'sendbird' && item.key_name === 'CHAT_KEY');
                  setApiKey(apiSecret?.key_value);
              } 
          };
          getApiKey();
      }, [dispatch]);
  
      useEffect(() => {
        if (!apiKey || !userSettings?.user_session_settings?.is_chat) {
            return; // Exit early if no API key or chat is disabled
        }
    
        const initializeChat = async () => {
            try {
                const API_KEY = apiKey;  
                const USER_ID = currentUser?.user_id;
                const ACCESS_TOKEN = accessToken;
    
                const sb = await SendbirdChat.init({
                    appId: API_KEY,
                    modules: [new GroupChannelModule()],
                });
    
                // ✅ Connect the user
                await sb.connect(USER_ID, ACCESS_TOKEN);
                await sb.updateCurrentUserInfo({
                    nickname: fullname,
                });
    
                // ✅ Fetch Unread Messages
                const fetchUnreadMessages = async () => {
                    const query = sb.groupChannel.createMyGroupChannelListQuery({
                        includeEmpty: true,
                    });
                    const channels = await query.next();
    
                    let totalUnreadCount = 0;
                    channels.forEach((channel) => {
                        totalUnreadCount += channel.unreadMessageCount;
                    });
    
                    dispatch(setTotalUnreadMessages(totalUnreadCount));
                };
    
                // ✅ Set up Message Handler
                const groupChannelHandler = new GroupChannelHandler({
                    onMessageReceived: (channel, message) => {
                        fetchUnreadMessages();
                    }
                });
    
                sb.groupChannel.addGroupChannelHandler("UNIQUE_HANDLER_ID", groupChannelHandler);
                fetchUnreadMessages();
            } catch (error) {
                // toast.error("Failed to initialize chat");
                printConsole(error)
            }
        };
    
        initializeChat();
    }, [apiKey, userSettings]);
     

  return (
    <div
      className={cx(
        "app-container",
        `app-theme-${colorScheme}`,
        { "fixed-header": enableFixedHeader },
        { "fixed-sidebar": enableFixedSidebar || windowWidth < 1250 },
        { "fixed-footer": enableFixedFooter },
        { "sidebar-mobile-open": enableMobileMenu },
        { "body-tabs-shadow-btn": enablePageTabsAlt }
      )}
    >
      <AppMain />
    </div>
  );
};

export default withRouter(Main);
