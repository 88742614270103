import { createSlice } from "@reduxjs/toolkit";

const initialToDateString = new Date();
const initialFromDateString = new Date();
initialFromDateString.setDate(initialFromDateString.getDate() - 7);

const initialState = {
    selectedFromDate: initialFromDateString,
    selectedToDate: initialToDateString,
    dateRange: 7,
    dateMsgDisplayed: "*D-7 days by default is displayed",
};

const dateSlice = createSlice({
    name: "date",
    initialState,
    reducers: {
        giveOutSelectedFromDate(state, action) {
            return {
                ...state,
                selectedFromDate: action.payload
            };
        },
        giveOutSelectedToDate(state, action) {
            return {
                ...state,
                selectedToDate: action.payload
            };
        },
        setDateMsgDisplayed(state, action) {
            return {
                ...state,
                dateMsgDisplayed: action.payload
            };
        },
        setDateRange(state, action) {
            return {
                ...state,
                dateRange: action.payload,
                dateMsgDisplayed: (state.dateMsgDisplayed !== "") ? `*D-${action.payload} days by default is displayed`: "",
            }
        },
    }
});

export default dateSlice.reducer;
export const { giveOutSelectedFromDate, giveOutSelectedToDate, setDateMsgDisplayed, setDateRange } = dateSlice.actions;