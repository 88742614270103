import React, { useEffect, useState, useRef, useCallback, memo } from "react";
import {
  StreamVideoClient,
  StreamVideo,
  StreamCall,
  RingingCall,
  StreamVideoEvent,
  Avatar
} from "@stream-io/video-react-sdk";
import "@stream-io/video-react-sdk/dist/css/styles.css";
import "./style/getstream.css";
import { createStreamClient } from "./streamClient.ts";
import { AES_256_GCM_Encrypt, fetchDecryptedUser, printConsole } from "../../../helpers.js";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Ringtone from "./ringtone/incomingCall.mp3";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import logo from "../../../assets/utils/images/tp-logo.png";
import { setIsWaitingForMembers } from "../../../store/features/user/userSlice.jsx";

const IncomingCallHandler = () => {
    const history = useHistory();
    const [client, setClient] = useState<StreamVideoClient>();
    const [ringer, setRinger] = useState(false);
    const [callerId, setCallerId] = useState('');
    const [callType, setCallType] = useState('');
    const [userData, setUserData] = useState<any>({});
    const [call, setCall] = useState<any>(null);
    const [clientUser, setClientUser] = useState<any>('');
    const [clientToken, setClientToken] = useState<any>({});
    const [receiverCustomData, setReceiverCustomData] = useState<any>([]);
    const [isInvitedToCall, setIsInvitedToCall] = useState<boolean>(false);
    const [apiKey, setApiKey] = useState<any>('');
    const dispatch = useDispatch();
    const audioRef = useRef<HTMLAudioElement>(null);
    const currentUser = useSelector((state:any) => state.user.currentUser);
    const userSettings = useSelector((state:any) => state.userSettings.userSettings);
    const [receiverCallSource, setReceiverCallSource] = useState<any>('');
    const [incomingAvatar, setIncomingAvatar] = useState<any>('');
    const allusers = useSelector((state:any) => state.allUsers.users);

    useEffect(()=>{
      if (currentUser?.app_tokens?.session_token!==null && userSettings?.user_session_settings?.is_streaming ===true) {
        InitializeCall()
      }else{
        printConsole('Error in initializing call')
      }
    },[currentUser, userSettings])

    //Initialise stream client credentials
    const InitializeCall = useCallback(async() =>{
      setApiKey(await getApiKey());
      setClientUser({
      id: currentUser.user_id,
      name: currentUser.first_name,
      image: 'https://getstream.io/random_svg/?id='+currentUser.first_name+'&name='+currentUser.first_name,
      })
      setClientToken(currentUser?.app_tokens?.session_token);
    },[currentUser])

    //Getting API key from DB
    const getApiKey = async () => {
      try {
        const keys = await fetchDecryptedUser(dispatch);
        const apiSecret = keys.find((item: { service_name: string; key_name: string; }) => item.service_name === 'getstream' && item.key_name === 'API_KEY');
        return apiSecret.key_value;
      } catch (error:any) {
        printConsole(`Error fetching API keys:${error}`);
      }
    };

    useEffect(()=>{
      if(clientUser.id!==undefined && apiKey!=='' && clientToken!==''){
        setUpCall();
      }else{
        printConsole('Error in setting up the client');
      }
      return () => {
        client?.off("call.session_participant_left", () => {printConsole('call participant left')});
        client?.off("call.session_participant_count_updated", () => {printConsole('call participant count updated')});
        client?.off("call.missed", () => {printConsole('call missed')});
        client?.off("call.ended", () => {printConsole('call ended')});
        client?.off("call.rejected", () => {printConsole('call rejected')});
        client?.off("call.ring", () => {printConsole('call ringed')});
      }
    },[clientUser, clientToken, apiKey])

    //if invite to user end the ringer after 15s
    useEffect(() => {
      if(isInvitedToCall === true){
        const timer = setTimeout(() => {
          setRinger(false);
          setIsInvitedToCall(false);
        }, 15000); // 15 seconds
    
        return () => clearTimeout(timer); // Cleanup on unmount
      }
    }, [isInvitedToCall]);

    //Initialise stream client
    const setUpCall = useCallback(async()=>{
      try{
        const streamClient = createStreamClient(clientUser, clientToken, apiKey);
        checkForRinger(streamClient);
        manageRinger(streamClient);
        manageCallEvents(streamClient);
        setClient(streamClient);
      }catch(error){
        printConsole(`Error in Intializing client:${error}`);
      }
    },[clientUser, clientToken, apiKey])

    //Listen for incoming call ring  
    const checkForRinger = async(streamClient:StreamVideoClient)=>{
      try{
        streamClient?.on("call.ring", async(event:any) => {
          await createCall(event.call.id, event.call.type, event.user);
          setReceiverCustomData(event?.call?.custom);
          setReceiverCallSource(event?.call?.id.split("-")[1]=='1to1'?'user':'group');
          setIncomingAvatar(allusers.filter((uu:any) => uu.user_id === event.user.id));
        });
      }catch(error){
        printConsole(`Error in Intializing ringer:${error}`);
      }

      try{
        streamClient?.on("call.member_added", async(event:any) => {
          if(event?.members?.filter((member:any)=>member?.user.id === currentUser.user_id).length>0) {
            await createCall(event?.call?.id, event?.call?.type, event?.call?.created_by);
            setReceiverCustomData(event?.call?.custom);
            setReceiverCallSource(event?.call?.id.split("-")[1]=='1to1'?'user':'group');
            setIncomingAvatar(allusers.filter((uu:any) => uu.user_id === event.call.created_by.id));
            setIsInvitedToCall(true);
          }
        });
      }catch(error){
        printConsole(`Error in invited ring:${error}`)
      }
    }

    //Initialize call ringer pop up
    const createCall = async (callid: any, calltype: any, userdata: any) => {
      try{
          //Available for a call - create a call 
          const callInstance = client?.call(calltype, callid);
          setCall(callInstance);
          setCallerId(callid);
          setRinger(true);
          setUserData(userdata);
          setCallType(calltype);
          if (audioRef.current) {
            audioRef.current.play();
          }
      }catch(error){
        printConsole(`Error in Accepting call:${error}`);
      }
    }

    //Manage ringer pop up based on call signals
    const manageRinger = async(streamClient:StreamVideoClient)=>{
      //Listen for Reject call events 
      try{ 
        streamClient?.on("call.rejected", (event:any) => {
          if(receiverCallSource == 'user'){
              setRinger(false); // Hide the ringing modal/UI
          }else{
            streamClient?.on("call.session_participant_count_updated", (partEvent:any) => {
              if (partEvent.type === 'call.session_participant_count_updated') {
                if (partEvent?.participants_count_by_role?.call_member <= 1) {
                  setRinger(false); // Hide the ringing modal/UI
                }
              }
            })
          }
        });
      }catch(error){
        printConsole(`Error in rejected call status check:${error}`);
      }
    
      //Listen for End call events 
      try{
        streamClient?.on("call.ended", (event:any) => {
          if (event.type === 'call.ended') {
            setRinger(false); // Hide the ringing modal/UI
          }
        });
      }catch(error){
        printConsole(`Error in ended call status check:${error}`);
      }
    
      //Listen for Missed call events 
      try{
        streamClient?.on("call.missed", (event:any) => {
     
          if (event.type === 'call.missed') {
            setRinger(false); // Hide the ringing modal/UI
          }
        });
      }catch(error){
        printConsole(`Error in missed call status check:${error}`);
      }
    
      //Listen for if any participant left 
      try{
        streamClient?.on("call.session_participant_left", (event) => {
        
          if (event.type === 'call.session_participant_left') {
            streamClient?.on("call.session_participant_count_updated", (partEvent:any) => {
           
              if (partEvent.type === 'call.session_participant_count_updated') {
                if (partEvent?.participants_count_by_role?.call_member <= 1) {
                  setRinger(false); // Hide the ringing modal/UI
                }
              }
            })
          }
        });
      }catch(error){
        printConsole(`Error in left the call status check:${error}`);
      }
    }

    //user will send the params require to join a call
    const connectUser = () => {
      printConsole("✅ Call accepted successfully!");
      const callId = btoa(JSON.stringify(AES_256_GCM_Encrypt(callerId)));
      const members = btoa(JSON.stringify(AES_256_GCM_Encrypt([{ user_id: clientUser?.id }, { user_id: userData?.id }])));
      const dynamicType = {
        tp_account_id: currentUser.customer_id,
        tp_call_from: "webapp",
        tp_call_source: receiverCallSource,
        tp_caller_name: `${currentUser.first_name} ${currentUser.last_name}`,
        tp_group_id: receiverCustomData.tp_group_id,
        tp_group_name: receiverCustomData.tp_group_name
      };
      const customData = btoa(JSON.stringify(AES_256_GCM_Encrypt(dynamicType)));
      history.push(`/session-stream?user=${members}&&call=${callId}&&custmdata=${customData}`);
    }

    const acceptCall = async () => {
      if (!ringer) return;
      try {
        connectUser()
        setRinger(!ringer)
        dispatch(setIsWaitingForMembers(false));
      } catch (error) {
        printConsole(`Error accepting call:${error}`);
      }
    };
    
    const rejectCall = async () => {
      if (!ringer) return;
      try {
        printConsole("✅ Call rejected successfully!");
        if(isInvitedToCall === false){
          const callInstance = client?.call(callType, callerId);
          await callInstance?.reject();
          //When rejecting an incoming call:
          //await call?.leave({ reject: true, reason: "declined" });
        } 
        setRinger(!ringer);
      } catch (error) {
        printConsole(`Error rejecting call:${error}`);
      }
    };
    
    const handleClosePopup = useCallback(() => {
        setRinger(!ringer)
    },[ringer])

    const manageCallEvents = (streamClient: StreamVideoClient) => {
      streamClient.on("call.ended", clearAllCalls);
      streamClient.on("call.rejected", clearAllCalls);
      streamClient.on("call.missed", clearAllCalls);
      streamClient.on("call.session_participant_left", clearAllCalls);
    };

    const clearAllCalls = () => {
      if(receiverCallSource == 'user'){
        printConsole("Clearing all calls...");
        setCall(null);
        setCallerId('');
        setRinger(false);
        setUserData({});
        setCallType('');
        if (client) {
            client.disconnectUser(); // Ensures user is disconnected from the stream
        }
      }else{
        client?.on("call.session_participant_count_updated", (partEvent:any) => {
          if (partEvent.type === 'call.session_participant_count_updated') {
            if (partEvent?.participants_count_by_role?.call_member <= 1) {
              setRinger(false); // Hide the ringing modal/UI
            }
          }
        })
      }
    };

  return (
    <Modal isOpen={ringer} toggle={handleClosePopup} centered className="border-0 shadow-none" backdrop="static">
        <div className="text-center p-3">
            <img src={logo} alt="Logo" style={{ width: "150px", height: "auto" }} />
        </div>
         <ModalBody className="text-center">
            <img src={'https://getstream.io/random_svg/?id='+userData?.name+'&name='+userData?.name} alt="Caller Avatar" className="rounded-circle mb-3" style={{ width: "80px", height: "80px" }} />
            <h5>{userData?.name} Calling...</h5>
            <p className="group-name">{(receiverCallSource === 'group') ? `Group call:${receiverCustomData.tp_group_name}`:``}</p>
        </ModalBody>
        <div className="d-flex justify-content-center gap-5 mb-2">
            <Button className="mb-2 me-2 btn-icon mt-2 px-3" color="success" size="sm" onClick={() => acceptCall()}>
                <i className="pe-7s-video btn-icon-wrapper"> </i> Accept
            </Button>
            <Button className="mb-2 me-2 btn-icon mt-2 px-3" color="danger" size="sm" onClick={() => rejectCall()}>
                <i className="pe-7s-close btn-icon-wrapper"> </i> Reject
            </Button>
            <audio ref={audioRef} id="ringerAudio" autoPlay loop>
                <source src={Ringtone} type="audio/mp3" />
            </audio>
      </div>
    </Modal>
  );
};

IncomingCallHandler.displayName = "IncomingCallHandler";

export default memo(IncomingCallHandler);
