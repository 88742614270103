let backend_domain = window._env_.REACT_APP_API_URL;
let api_version = window._env_.REACT_APP_API_VERSION;

//sessions api's
export const sessions_api = {
  sessiondata: {
    method: "PUT",
    url: `${backend_domain}${api_version}/session/initiate-session`,
    headers: { "Content-Type": "application/json" },
  },
  reset_group: {
    method: "PUT",
    url: `${backend_domain}${api_version}/session/reset-group`,
    headers: { "Content-Type": "application/json" },
  },
  get_keys: {
    method: "POST",
    url: `${backend_domain}${api_version}/get-keys`,
    headers: { "Content-Type": "application/json" },
  },
  getAllCalls: {
    method: "POST",
    url: `${backend_domain}${api_version}/session/get-call-data`,
    headers: { "Content-Type": "application/json" },
  },
  getAllUsages: {
    method: "POST",
    url: `${backend_domain}${api_version}/session/get-usage-data`,
    headers: { "Content-Type": "application/json" },
  },
  getLiveCalls: {
    method: "POST",
    url: `${backend_domain}${api_version}/session/get-live-call-data`,
    headers: { "Content-Type": "application/json" },
  },
};
