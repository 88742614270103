import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getTokenFromSession } from "../../../helpers";

import { current } from '@reduxjs/toolkit';
import { sessions_api } from "../../../API/services/session";

const initialState = {
  usages:[],
  calls:[],
  activeCalls :[],
  isLoading:false,
  error:null,
  success:false,
  isCallsLoading:false,
  errorCall:null,
  isActiveCallsLoading:false,
  errorActiveCall:null,

};


export const fetchAllUsageList = createAsyncThunk(
  'usages/get-all-usages',
  async (usages,{rejectWithValue} ) => {
    try{
      const token = await getTokenFromSession();
      const response = await axios.post(sessions_api.getAllUsages.url,usages,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
      
    }catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllCallList = createAsyncThunk(
  'usages/get-call-data',
  async (daterange,{rejectWithValue} ) => {
    try{
      const token = await getTokenFromSession();
      const response = await axios.post(sessions_api.getAllCalls.url,daterange,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data;
      
    }catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchLiveCallList = createAsyncThunk(
  'usages/get-live-call-data',
  async (activesession,{rejectWithValue} ) => {
    try{
      const token = await getTokenFromSession();
      const response = await axios.post(sessions_api.getLiveCalls.url,activesession,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
      
    }catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const getUsageSlice = createSlice({
  name: 'usages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(fetchAllCallList.pending, (state) => {
      state.isCallsLoading = true;
    })
    .addCase(fetchAllCallList.fulfilled, (state, action) => {
      state.calls = action.payload;
      state.isCallsLoading = false;
    })   
    .addCase(fetchAllCallList.rejected, (state, action) => {
      state.isCallsLoading = false;
      state.errorCall = action.payload;
    })
      .addCase(fetchAllUsageList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllUsageList.fulfilled, (state, action) => {
        state.usages = action.payload;
        state.isLoading = false;
      })   
      .addCase(fetchAllUsageList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchLiveCallList.pending, (state) => {
        state.isActiveCallsLoading = true;
      })
      .addCase(fetchLiveCallList.fulfilled, (state, action) => {
        state.activeCalls = action.payload;
        state.isActiveCallsLoading = false;
      })   
      .addCase(fetchLiveCallList.rejected, (state, action) => {
        state.isActiveCallsLoading = false;
        state.errorActiveCall = action.payload;
      })
  },
});

export default getUsageSlice.reducer;
// export addUsageSlice.actions