import "./polyfills";
import React from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./DemoPages/Main";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import { SocketClient } from "./utilities/Socket";
import IncomingCallHandler from "./DemoPages/Session/getstream_io/IncomingCallHandler.tsx";
import { StreamClientProvider } from "./utilities/StreamClientProvider.jsx";

const rootElement = document.getElementById("root");

const renderApp = (Component) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <SocketClient>
          <StreamClientProvider>
            <Component />
            <IncomingCallHandler />
          </StreamClientProvider>
        </SocketClient>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

const root = createRoot(rootElement).render(renderApp(Main));

if (module.hot) {
  module.hot.accept("./DemoPages/Main", () => {
    const NextApp = require("./DemoPages/Main").default;
    root.render(renderApp(NextApp));
  });
}
serviceWorker.unregister();
